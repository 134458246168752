import { useContext } from 'react';

import { useStoreWithEqualityFn } from 'zustand/traditional';

import { FilterStoreContext } from './filterContext';
import { FilterStoreState } from './filtersStore';

export function useFilterStore<T>(
	selector: (state: FilterStoreState) => T,
	equalityFn?: (left: T, right: T) => boolean,
): T {
	const store = useContext(FilterStoreContext);

	if (!store) throw new Error('Missing FilterStoreContext.Provider in the tree');

	return useStoreWithEqualityFn(store, selector, equalityFn);
}
