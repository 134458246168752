import { addBrandPrefix } from '@vakantiesnl/services/src/stores/brandPrefix';
import { AccommodationOnlyOffer, OfferItem } from '@vakantiesnl/types/src/search';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type OfferStore = {
	/** Used by package accommodations */
	departureDateOffer: OfferItem | undefined;
	flightsOffer: OfferItem | undefined;
	roomTypeOffer: OfferItem | undefined;
	mealplanOffer: OfferItem | undefined;

	setDepartureDateOffer: (offer: OfferItem | undefined) => void;
	setFlightsOffer: (offer: OfferItem | undefined) => void;
	setRoomTypeOffer: (offer: OfferItem | undefined) => void;
	setMealplanOffer: (offer: OfferItem | undefined) => void;

	/** Used by acco only accommodations  */
	accoOnlyDepartureDateOffer: AccommodationOnlyOffer | undefined;
	accoOnlyRoomTypeOffer: AccommodationOnlyOffer | undefined;

	setAccoOnlyDepartureDateOffer: (offer: AccommodationOnlyOffer | undefined) => void;
	setAccoOnlyRoomTypeOffer: (offer: AccommodationOnlyOffer | undefined) => void;

	/** Shared */
	// We need this loading state also here, because this store and the react query hook that trigger updates in this store are
	// not updated 100% in sync, which can cause strange UI jumps in certain cases (f.e. price block loading state).
	isDepartureDateLoading: boolean;

	setIsDepartureDateLoading: (isLoading: boolean) => void;
	clearOffers: () => void;
	getFinalOffer: (isAccoOnly: boolean) => OfferItem | AccommodationOnlyOffer | undefined;
	getCheapestOffer: (isAccoOnly: boolean) => OfferItem | AccommodationOnlyOffer | undefined;
};

export const useAccoOfferStore = create<OfferStore>()(
	devtools(
		(set, get) => ({
			departureDateOffer: undefined,
			flightsOffer: undefined,
			roomTypeOffer: undefined,
			mealplanOffer: undefined,

			accoOnlyDepartureDateOffer: undefined,
			accoOnlyRoomTypeOffer: undefined,

			isDepartureDateLoading: true,

			getFinalOffer: (isAccoOnly): OfferItem | AccommodationOnlyOffer | undefined => {
				const state = get();
				return isAccoOnly ? state.accoOnlyRoomTypeOffer : state.mealplanOffer;
			},

			getCheapestOffer: (isAccoOnly): OfferItem | AccommodationOnlyOffer | undefined => {
				const state = get();
				return isAccoOnly ? state.accoOnlyDepartureDateOffer : state.departureDateOffer;
			},

			setIsDepartureDateLoading: (isDepartureDateLoading): void =>
				set(
					(state) => ({
						...state,
						isDepartureDateLoading,
					}),
					undefined,
					'setIsDepartureDateLoading',
				),

			setDepartureDateOffer: (departureDateOffer): void =>
				set(
					(state) => ({
						...state,
						departureDateOffer,
						flightsOffer: undefined,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
					}),
					undefined,
					'setDepartureDateOffer',
				),

			setFlightsOffer: (flightsOffer): void =>
				set(
					(state) => ({
						...state,
						flightsOffer,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
					}),
					undefined,
					'setFlightsOffer',
				),

			setRoomTypeOffer: (roomTypeOffer): void =>
				set(
					(state) => ({
						...state,
						roomTypeOffer,
						mealplanOffer: undefined,
					}),
					undefined,
					'setRoomTypeOffer',
				),

			setMealplanOffer: (mealplanOffer): void =>
				set(
					(state) => ({
						...state,
						mealplanOffer,
					}),
					undefined,
					'setMealplanOffer',
				),

			setAccoOnlyDepartureDateOffer: (accoOnlyDepartureDateOffer): void =>
				set(
					(state) => ({
						...state,
						accoOnlyDepartureDateOffer,
						accoOnlyRoomTypeOffer: undefined,
					}),
					undefined,
					'setAccoOnlyDepartureDateOffer',
				),

			setAccoOnlyRoomTypeOffer: (accoOnlyAccoOffer): void =>
				set(
					(state) => ({
						...state,
						accoOnlyRoomTypeOffer: accoOnlyAccoOffer,
					}),
					undefined,
					'setAccoOnlyRoomTypeOffer',
				),

			clearOffers: (): void =>
				set(
					() => ({
						departureDateOffer: undefined,
						flightsOffer: undefined,
						roomTypeOffer: undefined,
						mealplanOffer: undefined,
						accoOnlyDepartureDateOffer: undefined,
						accoOnlyRoomTypeOffer: undefined,
					}),
					undefined,
					'clearOffers',
				),
		}),
		{
			name: addBrandPrefix('AccoStore'),
		},
	),
);
